import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login";
import Admin from "../views/Admin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Dashboard"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../views/Profile"),
      },
      {
        path: "/order-list",
        name: "order-list",
        query: {
          page: 1,
          reverse: "",
          sort: "",
          shopId: "",
          fromDate: "",
          toDate: "",
          status: "",
        },
        component: () => import("../views/OrderList"),
      },
      {
        path: "/receive-list",
        name: "receive-list",
        query: {
          page: 1,
          reverse: "",
          sort: "",
          shopId: "",
          fromDate: "",
          toDate: "",
          status: "",
        },
        component: () => import("../views/ReceiveList"),
      },
    ],
  },
  {
    path: "/*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
