<template>
    <div id="app">
        <div class="page-wrapper ice-theme sidebar-bg toggled">
            <nav id="sidebar" :class="['sidebar-wrapper', { 'nav_open': this.open, 'nav_close': this.close },]">
                <div class="sidebar-content">
                    <!-- sidebar-brand  -->
                    <!-- <div class="sidebar-item sidebar-brand" style="background: #ffff;">
                        
                    </div> -->
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header d-flex flex-nowrap">
                        <div class="user-pic">
                            <img class="img-responsive img-rounded"
                                src="https://myshre.com/assets/logos/logo-violet.svg">
                        </div>
                        <div class="user-info">
                            <span class="user-name">
                                <strong>{{ name }}</strong>
                            </span>
                            <span class="user-role text-capitalize">{{ role }}</span>
                        </div>
                    </div>
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <li style="margin-top: 12px;">
                                <router-link :class="[{ active: url === 'dashboard' }]" to="/dashboard">
                                    <i class="fas fa-tachometer-alt i-font"></i>
                                    <span class="menu-text">Dashboard</span>
                                </router-link>
                            </li>
                        </ul>
                        <ul>
                            <li style="margin-top: 12px;">
                                <router-link :class="[{ active: url === 'profile' }]" :to="{ path: 'profile' }">
                                    <i class="fas fa-user i-font"></i>
                                    <span class="menu-text">Account</span>
                                </router-link>
                            </li>
                        </ul>
                        <ul>
                            <li style="margin-top: 12px;">
                                <router-link :class="[{ active: url === 'order-list' }]"
                                    :to="{ path: 'order-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: '' } }">
                                    <i class="fas fa-hand-holding-usd i-font"></i>
                                    <span class="menu-text">Order</span>
                                </router-link>
                            </li>
                        </ul>
                        <ul>
                            <li style="margin-top: 12px;">
                                <router-link :class="[{ active: url === 'receive-list' }]"
                                    :to="{ path: 'receive-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: '' } }">
                                    <i class="fas fa-hand-holding-usd i-font"></i>
                                    <span class="menu-text">Receive</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
                <!-- sidebar-footer  -->
                <div class="sidebar-footer">

                    <div>
                        <a href="#" @click="logout()">
                            <i class="fa fa-power-off"></i>
                        </a>
                    </div>
                    <div class="pinned-footer">
                        <a href="#">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="layer" @click="closeNav()" :class="{ 'layer_open': this.open }"></div>
            <!-- page-content  -->
            <main class="page-content pt-2">
                <label class="icon admin-menu" @click="openNav()" for="check-menu" style="margin-left: 2%;">
                    &#9776;
                </label>
                <!-- <label class="translate">
                    <v-google-translate  @select="googleTranslateSelectedHandler"/>
                </label> -->
                <router-view />
            </main>
        </div>

    </div>
</template>
<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'admin',
    data() {
        return {
            saleCount: '',
            transferCount: '',
            productCount: '',
            check: true,
            close: true,
            open: false,
            name: '',
            role: '',
            url: '',
            permissions: "",
            languages: [
                {
                    code: "en",
                    name: "English",
                    cname: "英语",
                    ename: "English",
                },
                {
                    code: "af",
                    name: "Afrikaans",
                    cname: "南非语",
                    ename: "Afrikaans",
                },
            ]
        }
    },
    methods: {
        ...mapActions({
        }),
        googleTranslateSelectedHandler(language) {
            const { code, name, cname, ename } = language
            // todo ...
        },
        openNav: function () {
            this.open = !this.open
            console.log(this.open)
            if (this.check == true) {
                this.close = false
                this.open = true
            }
        },
        closeNav: function () {
            this.open = !this.open
            if (this.open == false) {
                this.close = true
                this.open = false
            }
        },
        checkPermission: function (key) {
            if (key in this.permissions) {
                return true
            } else {
                return false
            }
        },
        logout() {
            window.localStorage.setItem('token', null)
            this.$router.replace('/login')
        }
    },
    watch: {
        '$route'(to, from) {
            this.url = to.name
        },
    },
    mounted() {
        let user = JSON.parse(window.localStorage.getItem('user'))
        this.name = user.name
        this.role = user.role
        this.permissions = user.permissions
        this.url = this.$route.name
    }
}
</script>
<style lang="scss">
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
@import "../assets/css/main.css";
@import "../assets/css/sidebar-themes.css";

::-webkit-scrollbar {
    width: 6px;
    background-color: #525965;
}

.skiptranslate {
    display: none !important;
}

.eo__dropdown__menu {
    position: relative !important;
}

.translate {
    position: fixed;
    z-index: 100;
    top: 1px;
    right: 3%;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 50px;
    right: 2%;
    background-color: #0C9;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.float-loss {
    position: fixed;
    bottom: 50px;
    right: 8%;
    background-color: #007bffC9;
    color: #FFF;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.float-excel {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 117px;
    right: 2%;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.float-save {
    position: fixed;
    bottom: 50px;
    right: 2%;
    background-color: #007bffC9;
    color: #FFF;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.float-filter {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 120px;
    right: 2%;
    background-color: #318be3;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.btn-option {
    border: none;
    background: none;
    font-size: 24px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
    box-shadow: none !important;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.i-font {
    font-size: larger !important;
}

/* Change the color of links on hover */
.topnav a:hover {
    background-color: #ddd;
    color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
    background-color: #4CAF50;
    color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
    display: none;
}

// Table
.desc {
    background-repeat: no-repeat;
    background-position: right;
    /* width: 1em; height: 1em; */
    /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
    opacity: 1;
    /* padding: 3%; */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
}

.asc {
    background-repeat: no-repeat;
    background-position: right;
    /* width: 1em; height: 1em; */
    /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
    opacity: 1;
    /* padding: 3%; */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII= ');
}

.table_info {
    float: left;
}

.paging_simple_numbers {
    float: right;
}

th {
    text-align: center;
}

td {
    text-align: center;
    white-space: nowrap;
}

.th-width-50 {
    width: 50px;
}

.th-width-100 {
    width: 100px;
}

.th-width-200 {
    width: 200px;
}

.th-width-300 {
    width: 300px;
}

.tbl_th_a {
    text-decoration: none;
}

//
.active {
    color: #20c997 !important;
}

//table image
.tbl_image {
    width: 50px;
    height: 50px;
    background-size: contain;
}

.tbl_image_md {
    width: 100px;
    background-size: contain;
}

.tbl_pd_50 {
    padding-top: 50px !important;
}

.tbl_pd_bt_50 {
    padding-top: 40px !important;
}

//Filter Button
.fbutton {
    width: 49%;
}

//Search
.search-form {
    display: flex;
    justify-content: flex-end;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    height: 38px;
}

/* tootips */
.customtootip {
    position: relative;
    // display: inline-block;
}

.customtootip .customtootiptext {
    visibility: hidden;
    /* width: 120px; */
    background-color: #000000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    /* Position the customtootip */
    position: absolute;
    z-index: 1;
}

.customtootip:hover .customtootiptext {
    visibility: visible;
}
</style>