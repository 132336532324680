import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import env from "../enviorments";
import header from "../header";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    token: null,
    user: null,
  },
  getters: {},
  mutations: {
    mutateUser(state, user) {
      state.user = user;
    },
    mutateToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    //Auth
    async userLogin({ commit }, user) {
      const result = (await axios.post(env.apiUrl + "login", { ...user })).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      window.localStorage.setItem("token", JSON.stringify(result.data.token));
      (result.data.password = null),
        window.localStorage.setItem("user", JSON.stringify(result.data));
      commit("mutateToken", result.token);
      commit("mutateUser", result.data);
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    //Dashboard
    async dashboardAction({ commit }) {
      const result = (
        await axios.get(env.apiUrl + "dashboard", {
          headers: header.getToken(),
        })
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    async dashboardAction({ commit }) {
      const result = (
        await axios.get(env.apiUrl + "dashboard", {
          headers: header.getToken(),
        })
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    async profileAction({ commit }) {
      const result = (
        await axios.get(env.apiUrl + "profile", {
          headers: header.getToken(),
        })
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    async profileUpdateAction({ commit }, option) {
      const result = (
        await axios.post(
          env.apiUrl + "profile",
          { ...option },
          { headers: header.getToken() }
        )
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    async agentOrderListAction({ commit }, option) {
      const result = (
        await axios.post(
          env.apiUrl + "orders",
          { ...option },
          { headers: header.getToken() }
        )
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
    async agentReceiveListAction({ commit }, option) {
      const result = (
        await axios.post(
          env.apiUrl + "receives",
          { ...option },
          { headers: header.getToken() }
        )
      ).data;
      if (result.status == false)
        return { status: "fail", data: [], message: result.message, error: [] };
      return {
        status: "success",
        data: result.data,
        message: "Success",
        error: [],
      };
    },
  },
  modules: {},
});
